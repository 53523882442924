
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "9b281139-e8ff-432c-8925-185db33876ba"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/app/pruned/app.config.ts"
import cfg1 from "/app/pruned/node_modules/.pnpm/@shuriken-ui+nuxt@1.9.6_nuxt@3.8.2_postcss@8.4.32_vite@5.0.10_vue@3.3.12/node_modules/@shuriken-ui/nuxt/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, cfg1, inlineConfig)
