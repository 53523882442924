import revive_payload_client_bAitPLhz2w from "/app/node_modules/.pnpm/nuxt@3.8.2_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_u9woBAdhPZ from "/app/node_modules/.pnpm/nuxt@3.8.2_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GAK4GVIilj from "/app/node_modules/.pnpm/nuxt@3.8.2_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_e95srbsUlR from "/app/node_modules/.pnpm/nuxt@3.8.2_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/app/pruned/.nuxt/components.plugin.mjs";
import prefetch_client_Q80fGWAU1B from "/app/node_modules/.pnpm/nuxt@3.8.2_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_ijIezoaBVi from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_zgXHKq85CW from "/app/node_modules/.pnpm/@nuxtjs+kinde@0.1.4/node_modules/@nuxtjs/kinde/dist/runtime/plugin.mjs";
import chunk_reload_client_QA9uhQPARA from "/app/node_modules/.pnpm/nuxt@3.8.2_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_L1gVg9qcj6 from "/app/node_modules/.pnpm/nuxt@3.8.2_typescript@5.3.3_vite@5.0.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import date_fns_client_3ls07ysum5 from "/app/pruned/plugins/date-fns.client.ts";
import directives_8CcCirWtnE from "/app/pruned/plugins/directives.ts";
export default [
  revive_payload_client_bAitPLhz2w,
  unhead_u9woBAdhPZ,
  router_GAK4GVIilj,
  payload_client_e95srbsUlR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Q80fGWAU1B,
  plugin_client_ijIezoaBVi,
  plugin_zgXHKq85CW,
  chunk_reload_client_QA9uhQPARA,
  check_outdated_build_client_L1gVg9qcj6,
  date_fns_client_3ls07ysum5,
  directives_8CcCirWtnE
]