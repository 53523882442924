import { default as indexvUr3osDoa8Meta } from "/app/pruned/pages/app/index.vue?macro=true";
import { default as ordersS67tQg7hkVMeta } from "/app/pruned/pages/app/orders.vue?macro=true";
import { default as payoutssvNtcdNxpPMeta } from "/app/pruned/pages/app/payouts.vue?macro=true";
import { default as sourcesaiGMmixSF9Meta } from "/app/pruned/pages/app/sources.vue?macro=true";
import { default as indexDlzehCFPlrMeta } from "/app/pruned/pages/index.vue?macro=true";
export default [
  {
    name: indexvUr3osDoa8Meta?.name ?? "app",
    path: indexvUr3osDoa8Meta?.path ?? "/app",
    meta: indexvUr3osDoa8Meta || {},
    alias: indexvUr3osDoa8Meta?.alias || [],
    redirect: indexvUr3osDoa8Meta?.redirect || undefined,
    component: () => import("/app/pruned/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: ordersS67tQg7hkVMeta?.name ?? "app-orders",
    path: ordersS67tQg7hkVMeta?.path ?? "/app/orders",
    meta: ordersS67tQg7hkVMeta || {},
    alias: ordersS67tQg7hkVMeta?.alias || [],
    redirect: ordersS67tQg7hkVMeta?.redirect || undefined,
    component: () => import("/app/pruned/pages/app/orders.vue").then(m => m.default || m)
  },
  {
    name: payoutssvNtcdNxpPMeta?.name ?? "app-payouts",
    path: payoutssvNtcdNxpPMeta?.path ?? "/app/payouts",
    meta: payoutssvNtcdNxpPMeta || {},
    alias: payoutssvNtcdNxpPMeta?.alias || [],
    redirect: payoutssvNtcdNxpPMeta?.redirect || undefined,
    component: () => import("/app/pruned/pages/app/payouts.vue").then(m => m.default || m)
  },
  {
    name: sourcesaiGMmixSF9Meta?.name ?? "app-sources",
    path: sourcesaiGMmixSF9Meta?.path ?? "/app/sources",
    meta: sourcesaiGMmixSF9Meta || {},
    alias: sourcesaiGMmixSF9Meta?.alias || [],
    redirect: sourcesaiGMmixSF9Meta?.redirect || undefined,
    component: () => import("/app/pruned/pages/app/sources.vue").then(m => m.default || m)
  },
  {
    name: indexDlzehCFPlrMeta?.name ?? "index",
    path: indexDlzehCFPlrMeta?.path ?? "/",
    meta: indexDlzehCFPlrMeta || {},
    alias: indexDlzehCFPlrMeta?.alias || [],
    redirect: indexDlzehCFPlrMeta?.redirect || undefined,
    component: () => import("/app/pruned/pages/index.vue").then(m => m.default || m)
  }
]