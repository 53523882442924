import type { Directive } from 'vue'

const vFocus: Directive = {
  mounted(el: HTMLElement) {
    // If the element is not focusable, find the first focusable child
    if (el && el.tabIndex === -1) {
      const focusable = el.querySelector<HTMLElement>(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      )
      if (focusable) {
        focusable.focus()
      }
      return
    }

    el.focus()
  },
  getSSRProps() {
    return {}
  },
}

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive('focus', vFocus)
})
