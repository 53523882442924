export default defineAppConfig({
  nui: {
    defaultShapes: {
      input: 'straight',
      message: 'straight',
      button: 'straight',
      buttonAction: 'straight',
      dropdown: 'straight',
    }
  }
})